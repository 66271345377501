import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

type Props = object;

const Login: React.FC<Props> = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    function checkUser() {
      // This will run on component mount and anytime the isAuthenticated changes
      if (!isAuthenticated) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const invitation = params.get('invitation');
        const organization = params.get('organization');
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];

        if (invitation && organization) {
          loginWithRedirect({
            authorizationParams: {
              invitation,
              organization,
            }
          });
        } else if (subdomain.length > 0 && subdomain !== 'localhost' && subdomain !== '127.0.0.1' && subdomain !== 'dev' && subdomain !== 'app' && subdomain !== 'staging' && subdomain !== 'sandbox') {
          loginWithRedirect({
            authorizationParams: {
              organization: subdomain
            }
          });
        }
        else {
          loginWithRedirect();

        }

      }

    }

    checkUser();
  }, [isAuthenticated, loginWithRedirect]);

  return <></>;
};

export default Login;

