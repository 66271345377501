import { createApi } from '@reduxjs/toolkit/query/react';
import { resetChatState, updateCurrentSocketObject, updateSocketLoading, updateUserMessage } from './chatAdvisorSlice.slice';
import { io, Socket } from 'socket.io-client';
import { Dispatch } from 'redux';

interface Event<Data> {
  name: string;
  data: Data;
}

interface Options {
  url: string;
  event: Event<any>;
  token?: string;
}

interface Meta {
  confidence_score?: number;
  source_ids?: SourceId[];
  session_id?: string;
  metadata?: Metadata[];
  top_k?: number;
  rephrased_query?: string;
  message_id?: string;
}

interface Metadata {
  Displacement?: string;
}

interface SourceId {
  source_id: string;
  page_labels: string[];
}

interface Displacement {
  Displacement?: string;
}

interface AdvisorUIMessage {
  isAdvisor: boolean;
  isUser: boolean;
  message: string;
  metaData: Meta;
  isFeedbackAllowed: boolean;
  feedback: string;
  displacement: Displacement[];
  rephrased_query: string;
  comment: string;
  explainanswer: {
    answer: string,
    metaData: any,
    rephrased_query: string,
    reranked_context: any,
    retrieved_context: any
  }
}
let socket: Socket | null = null;
let connected: Promise<void> | null = null;
let listener: any;
export const connectSocket = (url: string, dispatch?: Dispatch, token?: string, isReconnect = false) => {
  if (socket) return;
  if (url) {
    socket = io(url, {
      reconnection: false,
      auth: {
        token: `Bearer ${token}`
      },
    });
    connected = new Promise<void>((resolve, reject) => {
      socket?.on('connect', () => {
        resolve();
        const socketPayload = {
          id: socket?.id,
          connected: socket?.connected,
        }
        dispatch && dispatch(updateCurrentSocketObject(socketPayload));
        dispatch && dispatch(updateSocketLoading(false));
        if (isReconnect) {
          socket?.on('bot_uttered', listener);
        }
      });
      socket?.on('connect_error', (error: any) => {
        dispatch && dispatch(updateSocketLoading(false));

        console.log('socket bob', socket)
        console.error('WebSocket connection error:', error);
        dispatch && dispatch(resetChatState());
        dispatch && cleanupSocket(dispatch);
      });

      socket?.on('disconnect', () => {
        console.log('WebSocket disconnected');
        dispatch && dispatch(resetChatState());
      });
    });
  }
};
export const disconnectSocket = (dispatch: Dispatch) => {
  if (socket) {
    Object?.keys(listener)?.forEach((eventName) => {
      socket?.off(eventName, listener[eventName]);
    });
    socket.disconnect();
    socket = null;
    connected = null;
    dispatch(chatAdvisorApi.util.resetApiState());
    cleanupSocket(dispatch);

  }
};
const cleanupSocket = (dispatch: Dispatch) => {
  if (socket) {
    socket.removeAllListeners(); // Remove all listeners to prevent memory leaks or unwanted behavior
    socket = null;
    connected = null;
    // Reset the state or show connection failed message
    // For example, update the state to indicate the connection failed
    dispatch && dispatch(updateSocketLoading(false));
    // Optionally, reset the API state or indicate a connection error in your UI
  }
};
export const chatAdvisorApi = createApi({
  reducerPath: 'chatAdvisorApi',
  tagTypes: ['Event'],
  async baseQuery(options: Options) {
    if (!socket) {
      connectSocket(options.url);
    }

    await connected;

    socket?.emit(options.event.name, options.event.data);

    return { data: options.event };
  },
  endpoints: (build) => ({

    sendEvent: build.mutation<Event<any>, Options>({
      query(options) {
        return options;
      },
      invalidatesTags: (result, error, { event }) => [{ type: 'Event', id: event.name }],
    }),
    events: build.query<{ value: number }[], Options>({
      queryFn() {
        return { data: [] };
      },
      keepUnusedDataFor: 0,
      providesTags: (result, error, { event }) => [{ type: 'Event', id: event.name }],
      async onCacheEntryAdded(
        { url, event, token },
        { cacheEntryRemoved, updateCachedData, cacheDataLoaded, dispatch }
      ) {
        if (!socket) {
          connectSocket(url, dispatch, token);
        }

        await cacheDataLoaded;
        await connected;

        listener = (message: any) => {
          const advisorUIMessage: Partial<AdvisorUIMessage> = {
            isAdvisor: true,
            isUser: false,
          }
          try {
            const parsedResponse = JSON.parse(
              message.text

            );
            advisorUIMessage.message = parsedResponse.predictions[0].answer
            advisorUIMessage.metaData = parsedResponse.predictions[0].meta || []
            advisorUIMessage.isFeedbackAllowed = parsedResponse.predictions[0].meta ? true : false
            advisorUIMessage.rephrased_query = parsedResponse?.predictions[0]?.meta[5]?.rephrased_query
            advisorUIMessage.displacement = parsedResponse?.predictions[0]?.meta[3]?.metadata || []
            advisorUIMessage.comment = ''
            advisorUIMessage.explainanswer = {
              answer: parsedResponse?.predictions[0]?.answer,
              rephrased_query: parsedResponse?.predictions[0]?.meta[5]?.rephrased_query,
              retrieved_context: parsedResponse?.predictions[0]?.retrived_context?.source_ids,
              reranked_context: parsedResponse?.predictions[0].meta[1]?.source_ids,
              metaData: parsedResponse?.predictions[0]?.meta[3]?.metadata || [],
            }
          } catch (e) {
            advisorUIMessage.message = message.text
            console.log('in catch', e)
          }
          dispatch(updateUserMessage(advisorUIMessage))
          dispatch(updateSocketLoading(false))
          updateCachedData((currentCacheData) => {
            currentCacheData.push(message);
          });
        };

        socket?.on(event.name, listener);
        await cacheEntryRemoved;
        socket?.off(event.name, listener);

        // Cleanup when the cache entry is removed
        if (socket?.listeners(event.name).length === 0) {
          console.log('No more listeners for event:');
          socket.disconnect();
          socket = null;
          connected = null;
        }

      },
    }),

  }),
});

export const { useSendEventMutation, useEventsQuery } = chatAdvisorApi;
