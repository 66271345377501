import apiEndPoints from "../../apiconstants";
import { api, responseArgs } from "../api";

export interface Report {
    id: string;
    name: string;
    embedUrl: string;
    webUrl: string;
    datasetId: string;
}

export type PbiAccessToken = {
    access_token: string;
    expires_in: number;
}

export type PbiEmbedToken = {
    embed_token: string;
    embed_token_expiration: string;
}
export const analyticsApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getPbiAccessToken: builder.query<PbiAccessToken, void>({
            query: () => apiEndPoints?.getPbiAccessToken,
            transformResponse: (response: responseArgs) => response.data as PbiAccessToken,
        }),
        getPbiEmbedToken: builder.query<PbiEmbedToken, { reportId: string }>({
            query: ({ reportId }) => `${apiEndPoints?.getpbiEmbedToken}/${reportId}`,
            transformResponse: (response: responseArgs) => response.data as PbiEmbedToken,
        }),
        getReportsList: builder.query<Report[], void>({
            query: () => `${apiEndPoints?.getReportsList}`,
            transformResponse: (response: responseArgs) => response.data as Report[],
        })
    })
});

export const { useGetPbiAccessTokenQuery, useGetPbiEmbedTokenQuery, useGetReportsListQuery } = analyticsApiSlice;