import apiEndPoints from "../../apiconstants";
import { api, deafultResponseAPIResponse } from "../api";
import { GetDetailsAPIResponse, SettingsData, SettingsTeamsAPIResponse } from "./settingstypes";

export interface TeamMember {
  invitee_email: string,
  role: string,
  event_type: string,
}

export const settingsApiSlice = api.injectEndpoints({
  endpoints: build => ({
    inviteUser: build.mutation<deafultResponseAPIResponse, Partial<TeamMember>>({
      query: ({ invitee_email, role }) => ({
        url: apiEndPoints?.inviteUser,
        method: 'POST',
        body: {
          invitee_email: invitee_email,
          role: role,
        },
      }),
      transformErrorResponse: (response: any) => response?.data,
    }),
    getTeamMembers: build.query<SettingsTeamsAPIResponse, void>({
      query: () => apiEndPoints?.getTeamMembers,
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
      providesTags: (result, error) => [{ type: "Settings" }], //? whey are we having the same type in different tags
    }),
    getDetails: build.query<GetDetailsAPIResponse, void>({
      query: () => apiEndPoints?.getDetails,
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
      providesTags: (result, error) => [{ type: "Settings" }]
    }),
    getGlobalSettings: build.query<SettingsData['settings'], void>({
      query: () => apiEndPoints?.getGlobalSettings,
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
      providesTags: (result, error) => [{ type: "Settings" }],
    }),
    getAdvisorAbilities: build.query<any, { advisor_id: string }>({
      query: ({ advisor_id }) => `${apiEndPoints?.getAdvisorAbilities}/${advisor_id}`,
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
      //  query: ({ advisor_id }) => `${apiEndPoints?.getAdvisorKnowledgeMetadata}/${advisor_id}`,
    }),
    updateSettings: build.mutation<deafultResponseAPIResponse, any>({
      query: (data) => ({
        url: apiEndPoints?.updateSettings,
        method: 'POST',
        body: {
          ...data
        }
      }),
      transformErrorResponse: (response: any) => response?.data,
    }),
    getUserProfileDetails: build.query<any, string>({
      query: (userId) => `${apiEndPoints?.getUserProfileDetails}/${userId}`,
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
    }),
    updateUserProfileDetails: build.mutation<deafultResponseAPIResponse, any>({
      query: ({ userID, userData }) => ({
        url: `${apiEndPoints?.getUserProfileDetails}/${userID}`,
        method: 'PATCH',
        body: {
          ...userData
        }
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    updateUserRole: build.mutation<deafultResponseAPIResponse, any>({
      query: ({ userID, roles }) => ({
        url: `${apiEndPoints?.getUserProfileDetails}/${userID}/roles`,
        method: 'PATCH',
        body: {
          roles: roles
        }
      }),
      transformErrorResponse: (response: any) => response?.data,
    }),
    // downloadSSOSAMLFile: build.query<BlobPart, void>({
    //   query: () => apiEndPoints?.downloadSSOSAMLFile,
    //   transformErrorResponse: (response: any) => response?.data,
    //   transformResponse: (response: any) => response?.data,
    // }),
    downloadSSOSAMLFile: build.mutation<BlobPart, any>({
      query: (payLoad) => ({
        url: `${apiEndPoints?.downloadSSOSAMLFile}`,
        method: 'POST',
        body: payLoad
      }),
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
    }),
    getSSOConnectionDetails: build.query<any, void>({
      query: () => apiEndPoints?.getSSOConnectionDetails,
      transformResponse: (response: any) => response?.data,
      transformErrorResponse: (response: any) => response?.data,
    }),
    createSSOConnction: build.mutation<any, any>({
      query: (payLoad) => ({
        url: `${apiEndPoints?.ssoConnectionPath}`,
        method: 'POST',
        body: payLoad
      }),
      transformErrorResponse: (response: any) => response?.data,
    }),
    deleteSSOConnection: build.mutation<any, string>({
      query: (connectionName) => ({
        url: `${apiEndPoints?.ssoConnectionPath}/${connectionName}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response: any) => response?.data,
    }),
  })
})

export const { useInviteUserMutation, useGetTeamMembersQuery, useGetDetailsQuery, useGetGlobalSettingsQuery, useGetAdvisorAbilitiesQuery, useUpdateSettingsMutation, useGetUserProfileDetailsQuery, useUpdateUserProfileDetailsMutation, useUpdateUserRoleMutation, useDownloadSSOSAMLFileMutation, useGetSSOConnectionDetailsQuery , useCreateSSOConnctionMutation, useDeleteSSOConnectionMutation } = settingsApiSlice;