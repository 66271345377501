import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, setApiConfig } from '@circuitry-ai/doc-data';
import './i18n';
import { Userpilot } from 'userpilot';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json';

//collect only in production mode
if (import.meta.env.PROD) {
  datadogRum.init({
    applicationId: import.meta.env.CTY_DATA_DOG_APP_ID,
    clientToken: import.meta.env.CTY_DATA_DOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: import.meta.env.CTY_DATA_DOG_SERVICE_NAME,
    env: import.meta.env.CTY_MODE,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: version,
    sessionSampleRate: 96,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  Userpilot.initialize('NX-5916a30a');
}
setApiConfig({ baseUrl: import.meta.env.CTY_API_URL || new URL(window.location.href)?.origin });
const store = createStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <Auth0ProviderWithNavigate>
      <BrowserRouter>
        <Provider store={store}>

          <App />

        </Provider>
      </BrowserRouter>
    </Auth0ProviderWithNavigate>
  </StrictMode>
);
