import { info } from 'console';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function CtyToast() {
  return (
    <ToastContainer />
  );
}

export const checkType = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

export const CtyShowToast = (type: string, message: string) => {
  if (type === checkType.success) {
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: true,
      theme: "light",
    });
  }
  else if (type === checkType.warning) {
    toast.warn(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: true,
      theme: "light",
    });
  }
  else if (type === checkType.error) {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: true,
      theme: "light",
    });
  }
  else if (type === checkType.info) {
    toast.info(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: true,
      theme: "light",
    });
  }
}

export default CtyToast;
