import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the API slice
export const fileUploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '', // No base URL needed since we'll be using pre-signed URLs
  }),
  endpoints: (builder) => ({
    uploadFileToS3: builder.mutation<void, { file: File, presignedUrl: string, CtyShowToast: (type: string, message: string) => void }>({
      query: ({ file, presignedUrl, CtyShowToast }) => ({
        url: presignedUrl,
        method: 'PUT',
        body: file,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        responseHandler: async (response) => {
          if (response.status === 200) {
            CtyShowToast('success', 'File Upload successful');
            return await response.text();
          } else {
            throw new Error('Upload failed');
          }
        },
      }),
      transformErrorResponse: (response: any) => response.data
    }),
  }),
});

export const { useUploadFileToS3Mutation } = fileUploadApi;
