import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'

export interface responseArgs {
  status_code: number
  message: string
  data: any
  response?: any
}

export interface APIBaseResponse {
  status_code: number;
  message: string;
}
export interface deafultResponseAPIResponse extends APIBaseResponse {
  data: { [key: string]: any };

}
export interface deafultResponseDatArrayAPIResponse extends APIBaseResponse {
  data: Array<any>;

}
let baseUrl = ''; // Default value or empty

export const setApiConfig = ({ baseUrl: newBaseUrl }: { baseUrl: string }) => {
  baseUrl = newBaseUrl;
};
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl || import.meta.env.CTY_API_URL || new URL(window.location.href)?.origin,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState)?.auth?.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = `${baseUrl}${urlEnd}`;
  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
  return baseQueryWithRetry(adjustedArgs, api, extraOptions);
}

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'ctyApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: dynamicBaseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['Advisor', 'ChatAdvisor', 'Dashboard', 'Conversations', 'Knowledge', 'Settings'],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
})
