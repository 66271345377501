import {
  createSlice,
} from '@reduxjs/toolkit';

export const CHAT_ADVISOR_SLICE_FEATURE_KEY = 'chatAdvisorSlice';

/*
 * Update these interfaces according to your requirements.
 */


export interface Message {
  isUser: boolean
  isAdvisor: boolean
  message: string
  feedback: string
  isFeedbackAllowed: boolean
  messageId: string
  metaData: any
  comment: string;
  rephrased_query: string
  figure_refs?: string[],
  explainanswer: {
    answer: string,
    metaData: any,
    rephrased_query: string,
    reranked_context: any,
    retrieved_context: any
  }
}
export interface ChatAdvisorSliceState {
  chatMessages: Message[],
  promptMessage?: string,
  selectedMessage?: string,
  currentActiveSocketObject: { [key: string]: any },
  isSocketLoading: boolean
  currentSocketListener: { [key: string]: any }
}



export const initialChatAdvisorSliceState: ChatAdvisorSliceState = {
  chatMessages: [],
  currentActiveSocketObject: {},
  isSocketLoading: false,
  currentSocketListener: {}
}

export const chatAdvisorSliceSlice = createSlice({
  name: CHAT_ADVISOR_SLICE_FEATURE_KEY,
  initialState: initialChatAdvisorSliceState,
  reducers: {
    updateUserMessage: (state, action) => {
      state.chatMessages.push(action.payload)
    },
    updateCurrentSocketObject: (state, action) => {
      state.currentActiveSocketObject = action.payload
    },
    updateMessageFeedback: (state, action) => {
      state.chatMessages = state.chatMessages?.map((message) => {
        if (message.isAdvisor && message?.metaData?.[6]?.message_id === action.payload.messageId) {
          if ('feedback' in action.payload && action.payload.feedback !== undefined) {
            return { ...message, feedback: action.payload.feedback }

          } else if ('comment' in action.payload && action.payload.feedback !== undefined) {
            return { ...message, comment: action.payload.comment }
          }
        }
        return message
      })
    },
    clearChatMessages: (state) => {
      state.chatMessages = []
      state.currentActiveSocketObject = {}
      state.isSocketLoading = false
    },

    updateSocketLoading: (state, action) => {
      state.isSocketLoading = action.payload
    },
    resetChatState: (state) => {

      state.chatMessages = []
      state.currentActiveSocketObject = {}
      state.isSocketLoading = false
    },
    updatePromptMessage: (state, action) => {
      state.promptMessage = action.payload
    },
    updateSelectedMessage: (state, action) => {
      state.selectedMessage = action.payload;
    },

    // ...
  }
});

/*
 * Export reducer for store configuration.
 */
export const chatAdvisorSliceReducer = chatAdvisorSliceSlice.reducer;
export const getMessageByIndex = (state: any, index: number) => {
  if (index < 0 || index > state.chatAdvisorSliceReducer.chatMessages.length) {
    return null;
  }

  return state?.chatAdvisorSliceReducer?.chatMessages[index - 1]?.message;
};
export const { updateUserMessage, updateCurrentSocketObject, updateMessageFeedback, clearChatMessages, updateSocketLoading, resetChatState, updatePromptMessage, updateSelectedMessage } = chatAdvisorSliceSlice.actions

