import apiEndPoints from '../../apiconstants';
import { GetUrlSourceListResponse } from '../advisor/advisortypes';
import { api, deafultResponseAPIResponse } from '../api';
import {
  AdvisorKnowledgeListApiResponse,
  AdvisorSpecificKnowledgeAPIResponse,
  expertAnswerKnowledgeMetadataPayload,
  GetKnowledgeMetadataApiResponse,
  Metadata,
  PresignedUrlResponse,
  UpdateKnowledgeMetadataApiResponse,
  UploadKnowledgeArticlePayload,
  UrlSourceListUpload,
} from './knowledgetypes';

export const knowledgeApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getKnowledgeAdvisorList: builder.query<
      AdvisorKnowledgeListApiResponse,
      void
    >({
      query: () => apiEndPoints?.getKnowledgeAdvisorList,
      providesTags: (result, error, id) => [
        { type: 'Knowledge', id: String(id) },
      ],
      transformErrorResponse: (response: any) => response.data,
    }),
    getAdvisorKnowledgeDetails: builder.query<
      AdvisorSpecificKnowledgeAPIResponse,
      string
    >({
      query: (advisorId) =>
        `${apiEndPoints?.getAdvisorKnowledgeDetails}/${advisorId}`,
      providesTags: (result, error, id) => [
        { type: 'Knowledge', id: String(id) },
      ],
      transformResponse: (response: any) => response.data,
      transformErrorResponse: (response: any) => response.data,
    }),
    getPresignedUrl: builder.mutation<
      PresignedUrlResponse,
      { filename: string; parts: number; advisor_id: string }
    >({
      query: ({ filename, parts, advisor_id }) => ({
        url: apiEndPoints?.getPresignedUrl,
        method: 'POST',
        body: {
          advisor_id: advisor_id,
          data: [
            {
              filename: filename,
              parts: parts,
            },
          ],
        },
      }),
      transformErrorResponse: (response: any) => response.data,
    }),

    getUploadUrlList: builder.mutation<
      GetUrlSourceListResponse,
      { knowledgeUploadURl: string; advisor_id: string }
    >({
      query: ({ knowledgeUploadURl, advisor_id }) => ({
        url: `${apiEndPoints?.getKnowledgeUrlSources}`,
        method: 'POST',
        body: {
          url: knowledgeUploadURl,
          advisor_id: advisor_id,
        },
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    uploadUrlSourceList: builder.mutation<
      deafultResponseAPIResponse,
      UrlSourceListUpload
    >({
      query: (UrlSourceListUpload) => ({
        url: `${apiEndPoints?.uploadUrlSourceList}`,
        method: 'POST',
        body: UrlSourceListUpload,
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    delinkKnowledge: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; source_id: string }
    >({
      query: ({ advisor_id, source_id }) => ({
        url: `${apiEndPoints?.delinkKnowledge}?advisor_id=${advisor_id}&source_id=${source_id}`,
        method: 'PATCH',
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    deleteKnowledge: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; source_id: string }
    >({
      query: ({ advisor_id, source_id }) => ({
        url: `${apiEndPoints?.deleteKnowledge}?advisor_id=${advisor_id}&source_id=${source_id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    getKnowledgeExpertAnswer: builder.query<any, string>({
      query: (expert_id) => `${apiEndPoints?.getExpertAnswer}/${expert_id}`,
      // providesTags: (result, error, id) => [{ type: "Knowledge", id: String(id) }], //TODO check this
      transformResponse: (response: any) => response.data,
      transformErrorResponse: (response: any) => response.data,
    }),
    deleteExpertAnswer: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; expert_id: string }
    >({
      query: ({ advisor_id, expert_id }) => ({
        url: `${apiEndPoints?.deleteExpertAnswer}?advisor_id=${advisor_id}&expert_id=${expert_id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    delinkExpertAnswer: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; expert_id: string }
    >({
      query: ({ advisor_id, expert_id }) => ({
        url: `${apiEndPoints?.delinkExpertAnswer}?advisor_id=${advisor_id}&expert_id=${expert_id}`,
        method: 'PATCH',
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    updateExpertAnswerKnowledgeMetaData: builder.mutation<
      deafultResponseAPIResponse,
      expertAnswerKnowledgeMetadataPayload
    >({
      query: (expertAnswerKnowledgeMetadataPayload) => ({
        url: `${apiEndPoints?.updateExpertAnswerMetaData}`,
        method: 'PATCH',
        body: expertAnswerKnowledgeMetadataPayload,
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    getAdvisorKnowledgeMetadata: builder.query<
      GetKnowledgeMetadataApiResponse,
      { advisor_id: string }
    >({
      query: ({ advisor_id }) =>
        `${apiEndPoints?.getAdvisorKnowledgeMetadata}/${advisor_id}`,
      transformErrorResponse: (response: any) => response.data,
    }),

    updateAdvisorKnowledgeMetadata: builder.mutation<
      UpdateKnowledgeMetadataApiResponse,
      { source_id: string; details: Metadata[] }
    >({
      query: ({ source_id, details }) => ({
        url: apiEndPoints?.updateAdvisorKnowledgeMetadata,
        method: 'PATCH',
        body: {
          source_id: source_id,
          updates: details,
        },
      }),
      transformErrorResponse: (response: any) => response.data,
    }),

    updateAdvisorKnowledgeDatabricks: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; sourceIdList: string[] }
    >({
      query: ({ advisor_id, sourceIdList }) => ({
        url: apiEndPoints?.updateAdvisorKnowledgeDatabricks,
        method: 'PATCH',
        body: {
          advisor_id: advisor_id,
          source_id: sourceIdList,
        },
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    trainCreateAdvisorKnowledgeDatabricks: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; sourceIdList: string[] }
    >({
      query: ({ advisor_id, sourceIdList }) => ({
        url: apiEndPoints?.trainCreateAdvisorKnowledgeDatabricks,
        method: 'POST',
        body: {
          advisor_id: advisor_id,
          source_id: sourceIdList,
        },
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    createKnowledgeArticle: builder.mutation<
      deafultResponseAPIResponse,
      UploadKnowledgeArticlePayload
    >({
      query: (uploadKnowledgeArticlePayload) => ({
        url: apiEndPoints?.uploadKnowledgeArticle,
        method: 'POST',
        body: uploadKnowledgeArticlePayload,
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    uploadKnowledgeArticle: builder.mutation<
      deafultResponseAPIResponse,
      UploadKnowledgeArticlePayload
    >({
      query: (uploadKnowledgeArticlePayload) => ({
        url: `${apiEndPoints?.uploadKnowledgeArticle}/${uploadKnowledgeArticlePayload.article_id}`,
        method: 'PATCH',
        body: uploadKnowledgeArticlePayload,
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    getKnowledgeArticle: builder.query<
      any,
      string
    >({
      query: (article_id) =>
        `${apiEndPoints?.getKnowledgeArticle}/${article_id}`,
      transformErrorResponse: (response: any) => response.data,
    }),
    deleteKnowledgeArticle: builder.mutation<
      deafultResponseAPIResponse,
      { article_id: string }
    >({
      query: ({ article_id }) => ({
        url: `${apiEndPoints?.deleteKnowledgeArticle}/${article_id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
    deLinkKnowledgeArticle: builder.mutation<
      deafultResponseAPIResponse,
      { advisor_id: string; article_id: string }
    >({
      query: ({ advisor_id, article_id }) => ({
        url: `${apiEndPoints?.delinkKnowledgeArticle}?advisor_id=${advisor_id}&article_id=${article_id}`,
        method: 'PATCH',
      }),
      transformErrorResponse: (response: any) => response.data,
    }),
  }),
});

export const {
  useGetKnowledgeAdvisorListQuery,
  useGetAdvisorKnowledgeDetailsQuery,
  useGetPresignedUrlMutation,
  useGetUploadUrlListMutation,
  useUploadUrlSourceListMutation,
  useDelinkKnowledgeMutation,
  useDeleteKnowledgeMutation,
  useGetKnowledgeExpertAnswerQuery,
  useDeleteExpertAnswerMutation,
  useDelinkExpertAnswerMutation,
  useGetAdvisorKnowledgeMetadataQuery,
  useUpdateExpertAnswerKnowledgeMetaDataMutation,
  useUpdateAdvisorKnowledgeMetadataMutation,
  useUpdateAdvisorKnowledgeDatabricksMutation,
  useTrainCreateAdvisorKnowledgeDatabricksMutation,
  useCreateKnowledgeArticleMutation,
  useUploadKnowledgeArticleMutation,
  useGetKnowledgeArticleQuery,
  useDeleteKnowledgeArticleMutation,
  useDeLinkKnowledgeArticleMutation,
} = knowledgeApiSlice;
