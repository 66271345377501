import { CtyButton } from "@circuitry-ai/doc-ui";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";


function PdfViewer() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabKey = queryParams.get('tabKey');
    const pdfFileName = useParams<{ fileName: any }>();
    const pdfData = JSON.parse(
        sessionStorage.getItem(`pdfData-${tabKey}`) ?? '{}'
    );
    const [isFullPdfOpen, setIsFullPdfOpen] = useState(false);
    const [currentPdfUrl, setCurrentPdfUrl] = useState(pdfData.initialPdfUrl);
    const handlePdfChanger = () => {
        setIsFullPdfOpen(!isFullPdfOpen);
        if (isFullPdfOpen) {
            setCurrentPdfUrl(pdfData.initialPdfUrl);
        } else {
            setCurrentPdfUrl(pdfData.fullPdfUrl);
        }
    }
    return (
        <div className="h-full w-full bg-[#323639]">
            <div className="w-full flex relative top-[1rem] left-[4rem] mb-[10px]">
                <CtyButton className="border-radius-0 bg-[white]" variant="solid" onPress={handlePdfChanger}>{isFullPdfOpen ? 'View Single Page' : `View Entire ${pdfFileName?.fileName}`}</CtyButton>
            </div>
            <iframe src={currentPdfUrl} className="w-full h-[100vh]" title="pdf"></iframe>
        </div>
    );
}

export default PdfViewer;