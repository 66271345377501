import { Spinner } from "@nextui-org/spinner";
/* eslint-disable-next-line */
export interface CtySpinnerProps {
  size: 'sm' | 'md' | 'lg';
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'white'
}


function CtySpinner(props: CtySpinnerProps) {
  return (

    <div data-testid='cty-spinner' className="flex items-center justify-center h-full">
      <Spinner color={props.color} size={props.size} />
    </div>


  );
}
export { CtySpinner };

export default CtySpinner;
