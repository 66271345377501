import {
  Modal,
  ModalBody,
  ModalContent,
  Tooltip,
  Link,
  ScrollShadow,
  Spacer
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import Sidebar, { SidebarItem } from "./Sidebardrawer";
import type { ModalProps } from "@nextui-org/react";

import React, { useEffect } from "react";
import { TRANSITION_EASINGS } from "@nextui-org/framer-utils";
import { PressEvent } from "@react-types/shared";
import { cn } from "./cn";
/* eslint-disable-next-line */
export interface SidenavProps {
  isOpen: boolean;
  onOpenChange: () => void;
  items: SidebarItem[];
  footerItems?: SidebarItem[];
  isCompact?: boolean;
  onClick?: (e: PressEvent) => void;
  onCollapseChange?: (isCollapsed: boolean) => void;
}

const SidebarDrawer = React.forwardRef<
  HTMLDivElement,
  ModalProps & {
    sidebarWidth?: number;
    sidebarPlacement?: "left" | "right";
    isCompact?: boolean;
  }
>(
  (
    {
      children,
      className,
      onOpenChange,
      isOpen,
      sidebarWidth = 288,
      classNames = {},
      sidebarPlacement = "left",
      motionProps: drawerMotionProps,
      isCompact,
      ...props
    },
    ref,
  ) => {
    const motionProps = React.useMemo(() => {
      if (!!drawerMotionProps && typeof drawerMotionProps === "object") {
        return drawerMotionProps;
      }

      return {
        variants: {
          enter: {
            x: 0,
            transition: {
              x: {
                duration: 0.3,
                ease: TRANSITION_EASINGS.easeOut,
              },
            },
          },
          exit: {
            x: sidebarPlacement === "left" ? -sidebarWidth : sidebarWidth,
            transition: {
              x: {
                duration: 0.2,
                ease: TRANSITION_EASINGS.easeOut,
              },
            },
          },
        },
      };
    }, [sidebarWidth, sidebarPlacement, drawerMotionProps]);

    return (
      <>
        <Modal
          ref={ref}
          {...props}
          classNames={{
            ...classNames,
            wrapper: cn("!w-[var(--sidebar-width)]", classNames?.wrapper, {
              "!items-start !justify-start ": sidebarPlacement === "left",
              "!items-end !justify-end": sidebarPlacement === "right",
            }),
            base: cn(
              "w-[var(--sidebar-width)] !m-0 p-0 h-full max-h-full",
              classNames?.base,
              className,
              {
                "inset-y-0 left-0 max-h-[none] rounded-l-none !justify-start":
                  sidebarPlacement === "left",
                "inset-y-0 right-0 max-h-[none] rounded-r-none !justify-end":
                  sidebarPlacement === "right",
              },
            ),
            body: cn("p-0", classNames?.body),
            closeButton: cn("z-50", classNames?.closeButton),
          }}
          isOpen={isOpen}
          motionProps={motionProps}
          radius="none"
          scrollBehavior="inside"

          style={{
            //@ts-expect-error nextui
            "--sidebar-width": `${sidebarWidth}px`,
          }}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            <ModalBody>{children}</ModalBody>
          </ModalContent>
        </Modal>
        <div
          className={cn(
            "h-full max-w-[var(--sidebar-width)] overflow-x-hidden overflow-y-hidden sm:flex",
            className,
            { "hidden": !isCompact }
          )}
        >
          {children}
        </div>
      </>
    );
  },
);


export function Sidenav(props: SidenavProps) {
  const { isOpen, onOpenChange, onClick, onCollapseChange, isCompact } = props;
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const hasFooterItems = props.footerItems && props.footerItems.length > 0;
  const onToggle = React.useCallback(() => {
    setIsCollapsed((prev) => !prev);
    onCollapseChange?.(!isCollapsed);
  }, [isCollapsed, onCollapseChange]);
  useEffect(() => { if (isCompact) { setIsCollapsed(true); } }, [isCompact]);

  const content = (
    <div className={` flex h-full ${isCompact ? 'w-full' : 'w-60'} flex-1 flex-col sidenav-wrapper `}>
      <div className="toggle-sidenav h-[0]">
        <div className={`${isCompact ? '' : 'sm:block'} hidden w-[26px]  h-[26px] p-[1px] top-[80px] bg-white rounded-full border-2 shadow-custom border-neutral-100 flex  justify-center items-center fixed ${isCollapsed ? 'left-[77px]' : 'left-[14rem]'} `}>
          <Link onPress={onToggle} className="cursor-pointer">
            {
              isCollapsed ? <Tooltip content="Expand" placement="top">
                <Icon icon="mdi:chevron-right" width="20" height="20" />
              </Tooltip>
                :
                <Tooltip content="Collapse" placement="top">
                  <Icon icon="mdi:chevron-left" width="20" height="20" xlinkTitle="close" />
                </Tooltip>
            }
          </Link>
        </div>
      </div>
      <ScrollShadow className={`h-full max-h-full  mt-1 ${!isCollapsed ? 'pr-6 ml-2' : 'pr-3'}`}>
        <Sidebar defaultSelectedKey="dashboard" items={props.items} isCompact={isCollapsed} onPress={onClick} />
      </ScrollShadow>
      {hasFooterItems && (
        <>
          <Spacer y={2} />
          <div className={cn("mt-auto flex flex-col bottom-0 absolute mb-4", {
            "items-center": isCollapsed,
          })}>
            <Sidebar defaultSelectedKey="" items={props.footerItems ?? []} isCompact={isCollapsed} onPress={onClick} />
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className={`flex w-full ${isCollapsed ? 'sm:w-[90px]' : ''}`}>

      <SidebarDrawer
        className=""
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isCompact={isCompact}
      >

        {content}

      </SidebarDrawer>
    </div>
  );
}

export default Sidenav;
