import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { api } from './features/api'
import auth from './features/auth/authSlice'
import advisor from './features/advisor/advisorSlice'
import { chatAdvisorApi } from './features/chatadvisor/chatAdvisorWebsocketSlice'
import { chatAdvisorSliceReducer } from './features/chatadvisor/chatAdvisorSlice.slice'
import { chatAdvisorHttpApi } from './features/chatadvisor/chatAdvisorApi'
import { dashboardApiSlice } from './features/dashboard/dashboardApiSlice'
import { conversationsApiSlice } from './features/conversations/conversationsApiSlice'
import { knowledgeApiSlice } from './features/knowledge/knowledgeApiSlice'
import { fileUploadApi } from './features/knowledge/knowledgeFileUploadApiSlice'
import { settingsApiSlice } from './features/settings/settingsApiSlice'
import { speechTokenApiSlice } from './features/speech/speechAuthApiSlice'
import { analyticsApiSlice } from './features/analytics/analyticsApiSlice'

export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      auth,
      advisor,
      [chatAdvisorApi.reducerPath]: chatAdvisorApi.reducer,
      chatAdvisorSliceReducer,
      chatAdvisorHttpApi,
      dashboardApiSlice,
      conversationsApiSlice,
      knowledgeApiSlice,
      fileUploadApi,
      settingsApiSlice,
      speechTokenApiSlice,
      analyticsApiSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, chatAdvisorApi.middleware, fileUploadApi.middleware),
    ...options,
  })

export type AppDispatch = ReturnType<typeof createStore>['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
